import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "./App.css";
import { Link } from 'react-router-dom';
import { useState } from 'react';

function MainNav() {

const [expanded, setExpanded] = useState(false);

  return (
    <Navbar expanded={expanded} expand="lg" fixed="top">
          <Container id="main-nav" fluid style={{backgroundColor: "#f1f4ea"}}>
            <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="responsive-navbar-nav" style={{border:"none"}}><Link id="menu-button-btn" title="Menu">Menu</Link></Navbar.Toggle>
            <Navbar.Collapse className="p-3" id="responsive-navbar-nav">
                <Nav>
                    <Nav.Item onClick={() => setExpanded(false)}><Link className="nav-link" to="/">Home</Link></Nav.Item>
                    <Nav.Item onClick={() => setExpanded(false)}><Link className="nav-link" to="/about">About Me</Link></Nav.Item>
                    <Nav.Item onClick={() => setExpanded(false)}><Link className="nav-link" to="/blog">Blog: Matcha Press</Link></Nav.Item>
                    <Nav.Item onClick={() => setExpanded(false)}><Link className="nav-link" to="/music">Music</Link></Nav.Item>
                </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
  );
}


export default MainNav;