import { Col, Image, Row } from "react-bootstrap";
import { Container } from "reactstrap";
import famPicture from "../fampic.jpeg"

const famPic = famPicture;

const About = () => {
    return <div className="p-2">
    <h1>About</h1>
    <h2 className="h5 blockquote"><i>We are afflicted in every way, but not crushed; perplexed, but not driven to despair; persecuted, but not forsaken; struck down, but not destroyed; always carrying in the body the death of Jesus, so that the life of Jesus may also be manifested in our bodies.</i></h2>
    <h2 style={{textDecoration: "underline"}}>Story</h2>
    <p>My story is coming soon!</p>
    <h3>Here is a simple breakdown of the facts:</h3>
    <Container>
<Row>
  <Col xs={12} md={4} lg={6}>
  <Image className="p-2" style={{border: "black 3px dashed"}} src={famPic} rounded fluid />
  </Col>
  <Col className="pt-3">
  <Col>
  <p><strong>Name:</strong>{" "}Bethany</p>
  </Col>
  <Col>
  <p><strong>Birth Year:</strong>{" "}1993</p>
  </Col>
  <Col>
  <p><strong>Languages:</strong>{" "}Native American English; Upper Elementary Japanese</p>
  </Col>
  <Col>
  <p><strong>Chinese Zodiac:</strong>{" "}Rooster</p>
  </Col>
  <Col>
  <p><strong>Enneagram:</strong>{" "}4w5</p>
  </Col>
  <Col>
  <p><strong>Roles:</strong>{" "}Wife, Mother, Daughter, and Sister; Church Admin, too</p>
  </Col>
  <Col>
  <p><strong>Main Areas of Expertise:</strong>{" "}Singing and composition; Front-end Web Development; and, Japanese language and culture.</p>
  </Col>
  <Col>
  <p><strong>Past times:</strong>{" "}Cooking/baking, writing, drawing, reading, walking, drinking tea, some sewing, some DIY projects, and trying to fix things. </p>
  </Col>
  </Col>
</Row>

    </Container>
    
    </div>
  };
  
  export default About;