import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Home = () => {
    return <>
    <Container fluid>
      <Row>
    <Col className="home-jumbo">
      <div className="d-flex justify-content-center align-items-center p-3" style={{color: "white", backgroundColor: "rgb(180, 197, 141, 0.5)"}}><h1>
        Welcome</h1>
        <h3 className="m-0 pl-1">| Please take a look around</h3>
    </div>
    </Col>
      </Row>
    </Container>
    </>
  };
  
  export default Home;