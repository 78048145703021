import React, { useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import sanityClient from "../client.js";
import { Row, Col, Container, Form, InputGroup, Button } from "react-bootstrap";
import SanityBlockContent from "@sanity/block-content-to-react";
import AllCategoriesList from "./AllCategoriesList.js";


export default function AllPosts({ match }) {
  const [allPostsData, setAllPosts] = useState(null);
  let lastPublishedAt = ''
  let lastId= ''

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post" && (
          publishedAt > $lastPublishedAt
          || (publishedAt == $lastPublishedAt && _id > $lastId)
        )] | order(publishedAt desc) [0...10]{
          _id,
        title,
        slug,
        body,
        publishedAt,
        mainImage{
          asset->{
          _id,
          url
        }
      },
      "categories": categories[]->title
    }`, {lastPublishedAt, lastId}
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  if (!allPostsData) return <div className="loader">Loading...</div>;

  function filterBoxToggle () {
    let filterBox = document.getElementById("postsFilterSortBox");
    if (filterBox.style.display === "block") {
      filterBox.style.display = "none";
    } else {
      filterBox.style.display = "block";
    }
}

  return (
    <Container className="p-2" fluid>
      <div className="d-flex justify-content-end m-2">
      <Button style={{backgroundColor: "lightGrey", color: "black", border: "none"}} onClick={filterBoxToggle}>Explore By Topic</Button></div>
       <Container id="postsFilterSortBox" fluid>
      <AllCategoriesList />
      </Container>
   {/*    <div className="d-flex justify-content-end m-2">
      <Button style={{backgroundColor: "lightGrey", color: "black", border: "none"}} onClick={() => {
        let filterBox = document.getElementById("postsFilterSortBox");
        if (filterBox.style.display === "none") {
          filterBox.style.display = "block";
        } else {
          filterBox.style.display = "none";
        }
      }
  }>Filter Posts</Button>
  </div>
      <Container id="postsFilterSortBox" fluid>
      <Row className="text-center">
        <Col>
      <Form>
        <h3>Filter Posts</h3>
<Form.Group controlId="postsFilterForm">
<Col>
<Form.Label>Tag(s) </Form.Label>
<Form.Select aria-label="tag select">
  <option>1</option>
  <option>2</option>
</Form.Select>
</Col>
<Col>
<Form.Label>Publish Year </Form.Label>
<Form.Select aria-label="publish year select">
  <option>2024</option>
</Form.Select>
</Col>
<Col>
<Form.Label>Publish Month </Form.Label>
<Form.Select aria-label="Publish Month">
  <option>1</option>
  <option>2</option>
</Form.Select>
</Col>
</Form.Group>

      </Form>
      </Col>
      <Col>
      <Form>
        <h3>Sort Post By:</h3>
<Form.Group controlId="postsFilterForm">
<Col>
<Form.Select>
  <option>Date (descending)</option>
  <option>Date (ascending)</option>
</Form.Select>
</Col>
</Form.Group>

      </Form>
      </Col>
      </Row>
      </Container> */}
      {/* <h2 className="text-center h4 p-2" style={{backgroundColor: "#B4C58D", border: "2px rgb(88,88,88) solid"}}>Most Recent Posts</h2> */}
        <Row className="m-3 text-center d-flex justify-content-center">  
        {allPostsData &&
          allPostsData.map((post, index) => (
                <Col xs={12} md={6} className="d-flex justify-content-center"> 
             <span key={index}>
                <span>
                <Link to={"/blog/" + post.slug.current} key={post.slug.current}><h2 className="mb-2">{post.title}</h2></Link>
                <hr />
                <Row className="mb-2">
                  <Col className="d-flex justify-content-around align-items-baseline"><span>{new Date(post.publishedAt).toDateString().slice(4)}</span>{" "}
                <span style={{fontSize: ".8em"}}>Tag(s): {post.categories.map((c, i) => (
                                        <span key={i} className="text-left">{c} </span>
                                    ))}</span></Col>
                </Row>
                <hr />
                  <p>
                <SanityBlockContent
          blocks={post.body[0]}
          projectId={sanityClient.projectId}
        />
        <Link to={"/blog/" + post.slug.current} key={post.slug.current}>Click to Read More</Link></p>
                </span>
                <Col xs={12}>
                <img src={post.mainImage.asset.url} alt="" style={{width: "80vw", maxWidth: "300px"}} className="mb-4"/>
                </Col>
              </span>
              <hr />
            </Col>
           
          ))
                }
          </Row>
      </Container>
  );
}