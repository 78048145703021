import { Container } from "react-bootstrap";
import AllPosts from "../Blog/AllPosts";
import OneCategory from "../Blog/AllCategoriesList";

const MyBlog = () => {

    return <Container fluid className="Blog p-2">  <h1 className="text-center" style={{backgroundColor: "#B4C58D", border: "2px rgb(88,88,88) solid", fontSize: "2.5em", fontFamily: "'Thasadith', sans-serif"}}>Matcha Press</h1>
  <AllPosts />
    </Container> 
  };
  
  export default MyBlog;