import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap";
import { Card } from "react-bootstrap";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log('totally custom!'),
  );

  return (
    <button
      type="button"
      style={{ backgroundColor: 'transparent', border: "none" }}
      onClick={decoratedOnClick}
      className="accordionToggle"
    >
      {children}
    </button>
  );
}

const Music = () => {
    return <div className="p-2">
    <h1>Music</h1>
    <h2>Playlist</h2>
    <iframe width="100%" height="300" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/457872003&color=%23806c68&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
    <div style={{fontSize: "10px", color: "#cccccc", lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif", fontWeight: "100"}}>
      <a href="https://soundcloud.com/bethanyannemusic" title="Bethany Anne Music" target="_blank" style={{color: "#cccccc", textDecoration: "none"}}>Bethany Anne Music</a> · 
      <a href="https://soundcloud.com/bethanyannemusic/sets/originals" title="Originals" target="_blank" style={{color: "#cccccc", textDecoration: "none"}}>Originals</a></div>
     
    <Accordion defaultActiveKey={["0"]} className="accordion-flush" flush>
<Card>
<CustomToggle eventKey="0"><Card.Header>
    <h3>Musical Biography</h3>
  </Card.Header></CustomToggle>
  <Accordion.Collapse eventKey="0">
    <Card.Body><p>I have been writing music since I was about eight years old, playing guitar since 11, and started singing and performing around that time as well. It's almost been 20 years! I have sang in gospel, concert, and jazz choirs and received professional training in classical and opera stylings. I have sang in church music bands for about as long as I started learning guitar.</p>
<p>Over the years, sharing the music I wrote was a struggle. Partly because I could never finish anything, but a lot of it due to feelings of inadequacy. It was not until I was 24 years old that I went to my first open mic night and shared some original music.
  I enjoyed being a part of the Sacramento Songwriter Circle and songwriter community for a couple of years in a casual manner, but also playing paid gigs when opportunities arose. I frequented The Fig Tree: Coffee, Art, and Music Lounge in Roseville, California. There, the owner saw me play at their open mic and invited me to play a gig there. This period in my life my fun and
  invigorating. 
</p>
<p>It was there that I realized that I had no desire to be an "artist". I didn't want to be hemmed in creatively, and be branded. I just wanted to create music I fancied, no matter if there was variety, and also enjoy the experience of sharing music and performing - which for me was about connecting with others in my community. 
  When I got married and moved states, things fell to the wayside, but I didn't miss shows as much as I wanted to just make music and enjoy performing with and for others.
</p>

    <p>I am currently on a music haitus as I am having children and nurturing babies, but here is some of the music that I was putting out before all that started!</p>
    <p>I feel as if I have changed so much, and my heart has healed so much that the music feels very distant now, both thematically and sound-wise. I look forward to making new music that represents my life and thoughts now after years have passed.</p></Card.Body>
  </Accordion.Collapse>
</Card>
<Card>
<CustomToggle eventKey="1"><Card.Header>
    <h3>Upcoming Events</h3>
  </Card.Header></CustomToggle>
  <Accordion.Collapse eventKey="1">
    <Card.Body>
      <div>
    <a className="bit-widget-initializer"
    
    data-artist-name="id_10953681"
    
    data-background-color="rgba(255,255,255,1)"
    data-separator-color="rgba(221,221,221,1)"
    data-text-color="#424242"
    data-font="Helvetica"
    data-auto-style="true"
    
    data-button-label-capitalization="capitalize"
    data-header-capitalization="undefined"
    data-location-capitalization="capitalize"
    data-venue-capitalization="capitalize"
    data-local-dates-position="tab"
    data-display-details="false"
    data-display-lineup=""
    data-display-start-time=""
    data-social-share-icon="true"
    data-display-limit="all"
    
    data-date-format="MMM. D, YYYY"
    data-date-orientation="horizontal"
    data-date-border-color="#4A4A4A"
    data-date-border-width="1px"
    data-date-capitalization="undefined"
    data-date-border-radius="10px"
    
    data-event-ticket-cta-size="medium"
    data-event-custom-ticket-text="undefined"
    data-event-ticket-text="TICKETS"
    data-event-ticket-icon=""
    data-event-ticket-cta-text-color="#FFFFFF"
    data-event-ticket-cta-bg-color="#4A4A4A"
    data-event-ticket-cta-border-color="#4A4A4A"
    data-event-ticket-cta-border-width="0px"
    data-event-ticket-cta-border-radius="4px"
    
    data-sold-out-button-text-color="#FFFFFF"
    data-sold-out-button-background-color="#4A4A4A"
    data-sold-out-button-border-color="#4A4A4A"
    data-sold-out-button-clickable="true"
    
    data-event-rsvp-position="left"
    data-event-rsvp-cta-size="medium"
    data-event-rsvp-only-show-icon="undefined"
    data-event-rsvp-text="REMIND ME"
    data-event-rsvp-icon=""
    data-event-rsvp-cta-text-color="#4A4A4A"
    data-event-rsvp-cta-bg-color="#FFFFFF"
    data-event-rsvp-cta-border-color="#4A4A4A"
    data-event-rsvp-cta-border-width="1px"
    data-event-rsvp-cta-border-radius="4px"
    
    data-follow-section-position="top"
    data-follow-section-alignment="center"
    data-follow-section-header-text="Get updates on new shows, new music, and more."
    data-follow-section-cta-size="medium"
    data-follow-section-cta-text="FOLLOW"
    data-follow-section-cta-icon="true"
    data-follow-section-cta-text-color="#FFFFFF"
    data-follow-section-cta-bg-color="#4A4A4A"
    data-follow-section-cta-border-color="#4A4A4A"
    data-follow-section-cta-border-width="0px"
    data-follow-section-cta-border-radius="4px"
    
    data-play-my-city-position="bottom"
    data-play-my-city-alignment="Center"
    data-play-my-city-header-text="Don’t see a show near you?"
    data-play-my-city-cta-size="medium"
    data-play-my-city-cta-text="REQUEST A SHOW"
    data-play-my-city-cta-icon="true"
    data-play-my-city-cta-text-color="#FFFFFF"
    data-play-my-city-cta-bg-color="#4A4A4A"
    data-play-my-city-cta-border-color="#4A4A4A"
    data-play-my-city-cta-border-width="0px"
    data-play-my-city-cta-border-radius="4px"
    
    data-optin-font=""
    data-optin-text-color=""
    data-optin-bg-color=""
    data-optin-cta-text-color=""
    data-optin-cta-bg-color=""
    data-optin-cta-border-width=""
    data-optin-cta-border-radius=""
    data-optin-cta-border-color=""
    
    data-language="en"
    data-layout-breakpoint="900"
    data-app-id=""
    data-affil-code=""
    data-bit-logo-position="bottomRight"
    data-bit-logo-color="#CCCCCC"
    
      ></a>
      <p>
        May 23rd- <a href="https://figtreecoffee.com/" target="_blank">The Fig Tree Coffee</a> @ 6pm
      </p>
      <button className="btn btn-large"><a href="https://bnds.us/fbwss0">Get Notified When I Have A Show Coming!</a></button>
</div>
</Card.Body>
  </Accordion.Collapse>
</Card><Card>
<CustomToggle eventKey="2"><Card.Header>
    <h3>Professional Services</h3>
  </Card.Header></CustomToggle>
  <Accordion.Collapse eventKey="2">
    <Card.Body>Starting in 2025, I will offer these services again:
<ul>
  <li>Individual Voice Lessons (Beginner through Upper Intermediate)</li>
  <li>Background music for your videos, presentations, etc.</li>
  <li>Live music at your event</li>
  <li>Music Theory and Listening lessons for your children, homeschool co-op, etc.</li>
</ul></Card.Body>
  </Accordion.Collapse>
</Card>


    </Accordion>

    </div>

  };
  
  export default Music;