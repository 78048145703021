import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}


export default function OneCategory() {
  const [postCategory, setPostCategoryData] = useState(null);
  const { slug } = useParams()

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug] {
            ...,
            "posts": *[_type == 'post' && references(^._id)]{
                title,
                publishedAt,
                mainImage,
                slug,
            }
          }`,
        { slug }
      )
      .then((data) => setPostCategoryData(data))
      .catch(console.error);
  }, [slug]);

  if (!postCategory) return <div className="loader" height="300px">Loading...</div>;

  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  

  return (
    <div className="p-3">
        <Link className="h5" to="/blog">Return to All Posts</Link>
        {postCategory.map((category, index) => (
<>
<div>
      <div key={index}>
      </div>
        <h2>{category.title}</h2>
      </div>
      <div>
      {category.posts.map((post, index) => (
         <Link to={"/blog/" + post.slug.current} key={post.slug.current}>
            <span key={index}>
                <h3>{post.title}</h3>
                <h4>{post.publishedAt}</h4>
                <img src={urlFor(post.mainImage).width(200).url()} alt="" />
            </span></Link>
        ))}
      </div>
</>

        ))}
         <Link className="h5" to="/blog">Return to All Posts</Link>
    </div>
  );
}