import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import './koisan.png';
import OffcanvasNav from "./functions/OffcanvasNav";
import { Link } from "react-router-dom";
import { scrollToBottom } from "./functions/scrollBottom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import MainNav from "./Navbar";


class MainHeader extends React.Component {

    render () {
        return <Container fluid loading="lazy" id="main-header-img" className="jumbotron">
        <Link to="/"><Row> 
        <Col>
        
        <h1 className="cursive" style={{fontSize: "4.5vw"}}>Bethany<br/>Stanley
        </h1>
        <h2 className="" style={{fontSize: "3vw"}}>
            Musician, Writer,<br/>
            Mother, Disciple
        </h2>
        </Col></Row></Link></Container>
        
    }
}

export default MainHeader;