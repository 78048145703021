import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import { Row, Col, Container, Form, InputGroup, Button } from "react-bootstrap";
import SanityBlockContent from "@sanity/block-content-to-react";

export default function AllCategoriesList() {
  const [allCategoriesData, setAllCategories] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "category"]{
          _id, title,
            "slug": slug.current
          }`
      )
      .then((data) => setAllCategories(data))
      .catch(console.error);
  }, []);

  if (!allCategoriesData) return <div className="loader">Loading...</div>;

  return (
<Container fluid>
    <Row>
{allCategoriesData.map((category, index) => (
<Col key={index} className="d-flex justify-content-center">
    <Link to={"category/" + category.slug} className="p-3">{category.title}</Link>
</Col>


))}
</Row>
</Container>

  )
}