import './App.css';
import { Container, Navbar } from 'react-bootstrap';
import MainNav from './Navbar';
import MainContent from './Content';
import MainFooter from './Footer';
import MainHeader from './Header';

function App() {
  return (
    <Container className="main-container" fluid>
      <MainNav />
<MainHeader/> 
<MainContent />
<MainFooter/>
    </Container>
  );
}

export default App;
